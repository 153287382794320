import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { ChatMessage } from '@prisma/client';
import { genericAuthRequest, RuntimeBackend, useMailberryQuery } from '../base';

export function useGetDemoMessages() {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<{ data: ChatMessage[]; message: string; error: boolean; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/messages`, {}, RuntimeBackend);
    },
    queryKey: [reloadTokens[ReloadTokenType.Chat]],
  });
}
