import BerryIcon from '@/assets/icons/BerryIcon';
import MessageBox from '@/components/screens/Chat/MessageBox';
import useInView from '@/services/hooks/useInView';
import { MESSAGE_AUTHOR } from '@/types/models';
import { Box, Center, Divider, Group, Loader, Text } from '@mantine/core';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { NewMessagesIndicator } from './NewMessagesIndicator';

interface IMessagesList {
  showNewMessages: boolean;
  newMessagesLoading: boolean;
  newMessages: any;
  readMessages: any;
  isMobile: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
}

export interface MessagesListRef {
  messagesTopRef: React.RefObject<HTMLDivElement>;
  messagesBottomRef: React.RefObject<HTMLDivElement>;
}

const MessagesList = forwardRef<MessagesListRef, IMessagesList>(
  (
    {
      showNewMessages,
      newMessagesLoading,
      newMessages,
      readMessages,
      isMobile,
      isLoading,
      isFetchingNextPage,
    },
    ref,
  ) => {
    const newMessagesRef = useRef<HTMLDivElement>(null);
    const newMessagesInView = useInView(newMessagesRef);
    const messagesBottomRef = useRef<HTMLDivElement>(null);
    const messagesTopRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({ messagesTopRef, messagesBottomRef }));

    const handleScrollToNewMessages = () => {
      if (!newMessagesInView) {
        messagesBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
      <>
        <NewMessagesIndicator
          newMessages={newMessages}
          newMessagesInView={newMessagesInView}
          handleScrollToNewMessages={handleScrollToNewMessages}
        />
        <Box
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: '0.5rem',
            padding: '1rem',
            position: 'relative',
            overflowY: 'auto',
            // width: '80%',
          }}
          pb={0}
          px={isMobile ? '' : '25%'}
        >
          <Box ref={messagesBottomRef}></Box>
          <Box ref={newMessagesRef}></Box>
          {(newMessages?.length > 0 || (showNewMessages && newMessagesLoading)) && (
            <>
              {(newMessagesLoading && newMessages.length < 1) && (
                <Center>
                  <Loader size={24} color='gray' />
                </Center>
              )}
              {newMessages?.map((m, i) => (
                <MessageBox
                  key={i}
                  message={m.text}
                  time={m.sentAt}
                  isSender={m.createdBy !== MESSAGE_AUTHOR.MB_PERSONNEL}
                  type={m.type}
                  metadata={m.metadata}
                />
              ))}
              <Divider
                my='xs'
                labelPosition='center'
                label={
                  <Group gap='xs' align='center'>
                    <BerryIcon style={{ width: 12, height: 12, fill: '#6B7280' }} />
                    <Text size='sm' c='gray'>
                      New Messages
                    </Text>
                  </Group>
                }
              />
            </>
          )}
          {!isLoading && readMessages?.length < 1 && newMessages?.length < 1
            ? (
              <Group justify='center'>
                <Text>Type something to start a conversation</Text>
              </Group>
            )
            : (
              readMessages?.map((m, i) => (
                <MessageBox
                  key={i}
                  message={m.text}
                  time={m.sentAt}
                  isSender={m.createdBy !== MESSAGE_AUTHOR.MB_PERSONNEL}
                  type={m.type}
                  metadata={m.metadata}
                />
              ))
            )}

          {isFetchingNextPage && (
            <Center>
              <Loader size={24} color='gray' />
            </Center>
          )}

          <Box
            ref={messagesTopRef}
            style={{
              height: 240,
              width: '100%',
            }}
          />
        </Box>
      </>
    );
  },
);

export default MessagesList;
