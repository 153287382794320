import DemoTabsMenu from '@components/screens/TabsMenu/DemoTabsMenu';
import { Box, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { Navbar } from './ui/mailberry/Navbar';

const DemoLayout = ({ children }: { children: React.ReactNode; }) => {
  let location = useLocation();
  const isMobileView = useMediaQuery('(max-width: 48em)');

  const showBackArrow = location.pathname !== '/demo';

  return (
    <Stack w='100%' maw={'1000px'} gap={0} style={{ height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )' }}>
      <Navbar logoRedirect='/demo' showBackArrow={showBackArrow} isMobileView={isMobileView} />
      <Box style={{ flexGrow: 2, grow: 2, borderBottom: '1px solid #E5E7EB', alignContent: 'center', overflow: 'scroll' }}>
        <div
          className={'tour-wrapper'}
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            width: '1px',
            height: '1px',
          }}
        >
        </div>
        {children}
      </Box>
      {isMobileView && !(location.pathname.startsWith('/demo/chat') || location.pathname.startsWith('/demo/review')) && <DemoTabsMenu />}
    </Stack>
  );
};

export default DemoLayout;
