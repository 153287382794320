import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllLastDemoReview } from '@/services/api/demo/review';
import AppState from '@/services/state/AppState';
import { Box, Button, Card, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useTour } from '@reactour/tour';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { STATUS_COLORS } from '../common';

export default function DemoReviewList({ loginCount }) {
  const { data, isLoading: isLoadingReviewList } = useGetAllLastDemoReview();
  const navigate = useNavigate();
  const { currentStep, setCurrentStep, setIsOpen, isOpen: isOpenTour } = useTour();
  const appState = AppState.useContainer();

  useLayoutEffect(() => {
    const tourCompleted = localStorage.getItem('tourCompleted-1');
    if (loginCount === 1 && data?.data?.length && !tourCompleted && tourCompleted !== '1') {
      setIsOpen(true);
    }

    if (currentStep === 13) {
      appState.userAndCustomer.setIsInTour(false);
    }

    if (appState.userAndCustomer.isInTour && data?.data?.length && currentStep === 4) {
      setCurrentStep(currentStep + 1);
    }

    if (appState.userAndCustomer.isInTour && data?.data?.length && currentStep === 7) {
      setCurrentStep(currentStep + 1);
    }
  }, [data?.data?.length, loginCount]);

  useEffect(() => {
    if (appState.userAndCustomer.isInTour && currentStep === 9) {
      setCurrentStep(currentStep + 1);
      setIsOpen(true);
    }
  }, [currentStep]);

  const onClickView = draftId => {
    navigate(`/demo/review/${draftId}`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      {isLoadingReviewList
        ? (
          Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} height={100} radius='md' animate />)
        )
        : (
          <Stack gap={0}>
            <Title order={4} fw={600} pb={'md'}>
              Upcoming Campaigns
            </Title>
            <Stack gap='md'>
              {data?.data?.length === 0
                ? <MBEmptyGeneric />
                : (
                  <>
                    {data?.data?.map((review, i) => {
                      const className = i === 0 ? `tour-first-step tour-fourth-step` : '';
                      return (
                        <Card className={className} key={review.id} shadow='sm' p='lg' radius='md' withBorder onClick={() => onClickView(review.demoDraftId)}>
                          <Text fw={500} size='lg'>{review.subject || 'No Subject'}</Text>
                          <Text size='sm' c='dimmed'>{review.preHeader || 'No Preheader'}</Text>
                          <Group justify='space-between'>
                            <Text size='sm' style={{ color: STATUS_COLORS[review.status] }}>
                              {review.status.charAt(0).toUpperCase() + review.status.slice(1).replaceAll('_', ' ')}
                            </Text>
                            <Group justify='right' mt={0}>
                              <Button variant={((currentStep === 1 || currentStep === 5) && i === 0) ? 'filled' : 'light'} color='blue' onClick={() => onClickView(review.demoDraftId)}>
                                View
                              </Button>
                            </Group>
                          </Group>
                        </Card>
                      );
                    })}
                  </>
                )}
            </Stack>
          </Stack>
        )}
    </Stack>
  );
}
