import { Button } from '@mantine/core';
import { StepType } from '@reactour/tour';
import React from 'react';

export const steps: StepType[] = [
  { // step 0
    position: 'center',
    styles: {
      close: base => ({ ...base, display: 'none' }),
      popover: base => ({ ...base, padding: '12px 100px', borderRadius: '12px' }),
    },
    selector: '.tour-wrapper',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Let's take a tour!</p>
        <Button bg={'#70C56E'} mx={'auto'} onClick={() => setCurrentStep(1)}>Start</Button>
      </div>
    ),
    stepInteraction: false,
  },
  { // step 1
    stepInteraction: true,
    selector: '.tour-first-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'left' }}>
        <p>
          Welcome to your "Nike" test account!
        </p>
        <p>Your email, fully crafted by Mailberry, is ready for review and just about to hit inboxes.</p>
        <p>Click "View" to see exactly how your campaign will look live.</p>
        <p>Take a few moments to double-check everything before it's ready to send!</p>
      </div>
    ),
    position: pos => {
      let x: number, y: number;
      if (pos.windowWidth < 970) {
        x = (pos.windowWidth / 2) - 195;
        y = 0;
      } else {
        x = (pos.windowWidth / 2) + 50;
        y = 80;
      }
      return [x, y];
    },
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(1) });
      },
    },
  },
  { // step 2
    stepInteraction: true,
    selector: '.tour-second-step',
    styles: {
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(2) });
      },
      close: () => ({ display: 'none' }),
    },
    position: pos => {
      if (pos.windowWidth < 970) {
        return 'center';
      } else {
        return [(pos.windowWidth / 2) - 200, 190];
      }
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <div style={{ textAlign: 'left' }}>
          <p>Go ahead and scroll down to check out the full email—from the subject line to the CTAs.</p>

          <p>Notice the sentence in purple: "Step up your style with Nike Cortez."</p>

          <p>Feels like it could pack more punch, right?</p>

          <p>Why not suggest something more exciting that highlights the key features?</p>

          <p>Click "Learn How to Submit Changes" and let's guide Mailberry on improving it!</p>
        </div>
        <Button bg={'#70C56E'} mx={'auto'} onClick={() => setCurrentStep(currentStep + 1)}>Learn How to Submit Changes</Button>
      </div>
    ),
  },
  {
    selector: '.tour-review-input',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        let arrowIndex: number;
        if (state.targetRect.windowWidth < 736) {
          arrowIndex = 3;
        } else {
          arrowIndex = 2;
        }
        return { ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(arrowIndex) };
      },
    },
    position: pos => {
      let x: number, y: number;
      if (pos.windowWidth < 768) {
        x = (pos.windowWidth / 2) - 200;
        y = pos.height - 80;
      } else {
        x = pos.windowWidth - 450;
        y = 300;
      }
      return [x, y];
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'left', borderRadius: '12px' }}>
        <p>Ready to make improvements?</p>

        <p>1. Simply click on any sentence, image, or button you want to tweak.</p>
        <p>2. A comment box will pop up on the right—this is where you can share your thoughts.</p>
        <p>3. Let’s suggest to Mailberry that we add more energy and excitement to the purple-highlighted sentence!</p>
        <p>4. Once done commenting, just hit "Save Comment”.</p>
      </div>
    ),
  },
  { // step 3
    stepInteraction: true,
    selector: '.tour-third-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'left', borderRadius: '12px' }}>
        <p>1. After submitting all your changes, just scroll down to find the "Submit Changes" button.</p>

        <p>2. Give it a click, and let Mailberry work its magic—your updates will be back to you in no time!</p>
      </div>
    ),
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(4) });
      },
    },
  },
  { // step 4
    // stepInteraction: false,
    selector: '.tour-fourth-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'left', borderRadius: '12px' }}>
        <p>Thanks for your collaboration on the emails!</p>
        <p>Mailberry has made the updates you requested.</p>
        <p>Your email is ready for final review.</p>
        <p>Click "View" to check it out!</p>
      </div>
    ),
    position: pos => {
      let x: number, y: number;
      if (pos.windowWidth < 970) {
        x = (pos.windowWidth / 2) - 200;
        y = 120;
      } else {
        x = (pos.windowWidth / 2) + 50;
        y = 160;
      }
      return [x, y];
    },
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(1) });
      },
    },
  },
  { // step 5
    stepInteraction: false,
    selector: '.tour-second-step',
    position: 'center',
    styles: {
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px' });
      },
      close: () => ({ display: 'none' }),
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <div style={{ textAlign: 'left' }}>
          <p>Take a look at the changes highlighted in purple.</p>
          <p>If all looks good, scroll down to approve and let’s send it out!</p>
        </div>
        <Button bg={'#70C56E'} onClick={() => setCurrentStep(currentStep + 1)}>Scroll down</Button>
      </div>
    ),
  },
  { // step 6
    selector: '.tour-fifth-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'left', borderRadius: '12px' }}>
        <p>Simply click "Approve Email as Is" to send this email out to your list!</p>
      </div>
    ),
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(5) });
      },
    },
  },
  { // step 7
    // stepInteraction: false,
    selector: '.tour-wrapper',
    position: 'center',
    content: ({ setCurrentStep, setIsOpen }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Awesome! Your email is on its way. Now, let's check your inbox to see it in action.</p>
        <Button bg={'#70C56E'} onClick={() => setIsOpen(false)}>Close</Button>
      </div>
    ),
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px' });
      },
    },
    actionAfter: () => localStorage.setItem('tourCompleted-1', '1'),
  },
  { // step 8
    // stepInteraction: false,
    selector: '.tour-chat',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(5) });
      },
    },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Mailberry is here for you! If you ever need help, go to our chat feature right within the app. Go ahead and try it out.</p>
      </div>
    ),
  },
  { // step 9
    // stepInteraction: false,
    selector: '.tour-reports',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        let arrowIndex: number;
        if (state.targetRect.windowWidth < 736) {
          arrowIndex = 8;
        } else {
          arrowIndex = 6;
        }
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(arrowIndex) });
      },
    },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Great job! Now, let’s check out how your email is performing. Mailberry tracks everything for you in real time</p>
        <p>Head over to the 'Reports' section to see detailed insights. Click on the latest email you just sent to dive into the stats</p>
      </div>
    ),
  },
  { // step 10
    // stepInteraction: false,
    selector: '.tour-report-delivery',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        let arrowIndex: number;
        if (state.targetRect.windowWidth < 736) {
          arrowIndex = 9;
        } else {
          arrowIndex = 9;
        }
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(arrowIndex) });
      },
    },
    position: pos => {
      let x: number, y: number;
      if (pos.windowWidth < 970) {
        x = (pos.windowWidth / 2) - 200;
        y = 190;
      } else {
        x = (pos.windowWidth / 2) + 50;
        y = 190;
      }
      return [x, y];
    },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Click on the latest email you just sent to dive into the stats.</p>
      </div>
    ),
  },
  { // step 11
    stepInteraction: false,
    selector: '.tour-report-stats',
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px', ...doArrow(6) });
      },
    },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Here, you can see how many people have opened your email and clicked on the links inside. These metrics help you understand your audience's engagement.</p>
        <Button bg={'#70C56E'} onClick={() => setCurrentStep(13)}>Next</Button>
      </div>
    ),
  },
  { // step 12
    stepInteraction: false,
    position: 'center',
    selector: '.tour-wrapper',
    content: ({ setCurrentStep, setIsOpen }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>
          Congratulations! You've just completed the Mailberry demo and successfully sent your first email. From seamless content creation to real-time performance tracking, Mailberry transforms email
          marketing into an effortless, automated process. It's time to upgrade from your old-school ESP to the next generation of email marketing. Ready to take your email marketing to the next
          level?
        </p>
        <Button
          bg={'#70C56E'}
          onClick={() => {
            setIsOpen(false);
            window.open('https://mailberry.ai/book-a-call/', '_blank');
            window.location.href = '/demo';
          }}
        >
          Start Using Mailberry for your business
        </Button>
      </div>
    ),
    styles: {
      close: () => ({ display: 'none' }),
      popover: (base, state) => {
        return ({ ...base, borderRadius: '12px', maxWidth: '400px' });
      },
    },
    actionAfter: () => localStorage.setItem('tourCompleted-2', '1'),
  },
];

function doArrow(step: number) {
  const base = {
    [`--rtp-arrow-border-left`]: `10px solid transparent`,
    [`--rtp-arrow-border-right`]: `10px solid transparent`,
  };
  if (step === 1) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '30px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 2) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '190px',
      [`--rtp-arrow-top`]: '-20px',
      [`--rtp-arrow-border-bottom`]: `24px solid white`,
    };
  } else if (step === 3) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '190px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 4) {
    return {
      ...base,
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 5) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '20px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 6) {
    return {
      ...base,
      [`--rtp-arrow-left`]: '20px',
      [`--rtp-arrow-top`]: '-20px',
      [`--rtp-arrow-border-bottom`]: `24px solid white`,
    };
  } else if (step === 7) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '20px',
      [`--rtp-arrow-top`]: '-20px',
      [`--rtp-arrow-border-bottom`]: `24px solid white`,
    };
  } else if (step === 8) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '80px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  } else if (step === 9) {
    return {
      ...base,
      [`--rtp-arrow-right`]: '80px',
      [`--rtp-arrow-bottom`]: '-20px',
      [`--rtp-arrow-border-top`]: `24px solid white`,
    };
  }
}
