import { useGetDemoAccount, useRestartDemo } from '@/services/api/demo/account';
import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { Box, Button, LoadingOverlay, Space, Stack } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountInfo from '../AccountInfo';
import OverallStats from '../OverallStats';
import DemoReviewList from './DemoReviewList';

export default function DemoOverview() {
  const navigate = useNavigate();
  const { data, isLoading: isLoadingUser } = useGetDemoAccount();
  const restartDemo = useRestartDemo();
  const { currentStep, setCurrentStep, setIsOpen, isOpen: isOpenTour } = useTour();
  const { setReloadToken } = ReloadTokens.useContainer();

  const resetDemo = () => {
    restartDemo.mutateAsync({})
      .then(() => {
        setReloadToken(ReloadTokenType.Review);
        localStorage.removeItem('tourCompleted-1');
        localStorage.removeItem('tourCompleted-2');
        setCurrentStep(0);
        setIsOpen(true);
      });
  };

  return (
    <Stack pos={'relative'} gap={0}>
      <LoadingOverlay visible={restartDemo.isLoading} />
      <AccountInfo account={data?.data} isLoading={isLoadingUser} isDemo />
      <OverallStats account={data?.data} isLoading={isLoadingUser} />
      <DemoReviewList loginCount={data?.data.loginCount} />
      <Button my={24} mx={'auto'} onClick={resetDemo}>Check out demo again</Button>
      <Space h={20} />
    </Stack>
  );
}
